import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  FlexEndColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";

import { Link } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import { styled } from "@linaria/react";
import React from "react";

import emailIcon from "../icons/email.png";
import locationIcon from "../icons/location.svg";
import phoneIcon from "../icons/phone.png";
import { formatTelephoneNumber } from "../util/helperFunctions";
import { sendCustomGtagEvent } from "components/form/util/functions";
import { ButtonLinkSmall } from "components/rui/buttons";
import {
  Body,
  HeroTitle,
  PreTitle,
  TitleSmall,
} from "components/rui/typography";

const ContactHeroContainer = styled(SectionContainer)`
  background: var(--gradient-light);
  padding-bottom: 1.25rem;
  @media (max-width: 600px) {
    padding: 5rem 0;
    padding-bottom: 0;
  }
`;

const SectionLeft = styled(SectionCol)`
  flex-direction: column;
  gap: 2.5rem;
  @media (max-width: 600px) {
    padding: 0 1.25rem;
    padding-bottom: 2.5rem;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const LinkIcon = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  aspect-ratio: 1;
  border-radius: 50%;
  flex-shrink: 0;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--tertiary-bg);
`;

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  border-radius: 0.75rem;
  width: fit-content;
  padding: 0.5rem;
  padding-right: 1rem;
  transition: all 250ms;
  text-decoration: none;
  color: inherit;
  :hover {
    background: var(--blue-100);
  }
`;

const ContactHero = ({
  pretitle,
  title,
  blurb,
  formId,
  location = false,
  contactBlurb = false,
  telephone = "+18777466797",
  email = "sales@rhombus.com",
  useTracking = false,
}) => {
  const { isMobile } = useIsMobile(820);
  return (
    <ContactHeroContainer>
      <SectionInner>
        <SectionLeft>
          <TextContainer>
            <PreTitle color="var(--primary-bg)">{pretitle}</PreTitle>
            <HeroTitle color="var(--primary-bg)">{title}</HeroTitle>
            <Body>
              {contactBlurb ? (
                <>
                  Fill out your information and a Rhombus representative will
                  reach out to you.
                  <br /> <b>Have a simple question?</b> Check out our{" "}
                  <ButtonLinkSmall
                    href="https://support.rhombussystems.com/hc/en-us"
                    target="_blank"
                    rel="noreferrer"
                    title="support resources"
                  >
                    support resources
                  </ButtonLinkSmall>
                  .<br /> <b>Interested in becoming a partner?</b> Learn about
                  our{" "}
                  <ButtonLinkSmall
                    as={Link}
                    to="/partner-program/"
                    title="Partner Program"
                  >
                    Partner Program
                  </ButtonLinkSmall>
                  .
                </>
              ) : (
                blurb
              )}
            </Body>
          </TextContainer>
          <LinkContainer>
            <ContactLink
              href={`tel:${telephone}`}
              onClick={() => useTracking && sendCustomGtagEvent("phone_7404")}
            >
              <LinkIcon>
                <img src={phoneIcon} height={32} width={32} />
              </LinkIcon>
              <div>
                <TitleSmall>{formatTelephoneNumber(telephone)}</TitleSmall>
                <p>Monday - Friday, 8 AM - 5 PM PT </p>
              </div>
            </ContactLink>
            <ContactLink
              href={`mailto:${email}`}
              onClick={() =>
                useTracking && sendCustomGtagEvent("email_quote_at_rhombus")
              }
            >
              <LinkIcon>
                <img src={emailIcon} height={19} width={32} />
              </LinkIcon>
              <div>
                <TitleSmall>{email}</TitleSmall>
              </div>
            </ContactLink>
            {location && (
              <ContactLink
                href="https://www.google.com/maps?sca_esv=040907f6f53834d3&sca_upv=1&rlz=1C5GCEM_enUS1057US1057&output=search&q=1610+R+Street,+Suite+350,+Sacramento,+CA+95811&source=lnms&fbs=AEQNm0Aa4sjWe7Rqy32pFwRj0UkW1DRbm01j6DCVS0r1sTxn7lWrPPt54xvTeoI9jYBmanbcnj_OgzaZVVS-VpZSx-qFrSmhdDGfix58OmxfbNsKX6T9illdRFK72gouKUeB7KKfxWkXfIKKaiXhOq76GFVQTyQkWp1r6eypFZD_L44haSdPhHvOAgW-0jsghDlpSil00V33aa0iUlmJykf__La_03G-rA&entry=mc&ved=1t:200715&ictx=111"
                target="_blank"
              >
                <LinkIcon>
                  <img src={locationIcon} height={36} width={25} />
                </LinkIcon>
                <div>
                  <TitleSmall>
                    1610 R Street, Suite 350, Sacramento, CA 95811
                  </TitleSmall>
                </div>
              </ContactLink>
            )}
          </LinkContainer>
        </SectionLeft>
        <FlexEndColumn>
          <FormWrapper width={isMobile ? "100%" : "550px"}>
            <RhombusForm formId={formId} />
          </FormWrapper>
        </FlexEndColumn>
      </SectionInner>
    </ContactHeroContainer>
  );
};

export default ContactHero;
