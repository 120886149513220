import IconCheckMark from "components/common/icon-components/IconCheckMark";
import RhombusButton from "components/common/RhombusButton";
import {
  CTA_BUTTON_COPY_3,
  CTA_LINK_4,
} from "components/data/landing-page-data";
import {
  FlexCenter,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { StaticImage } from "gatsby-plugin-image";
import { styled } from "@linaria/react";
import React from "react";
import { Body, TitleMed } from "components/rui/typography";

const Text = styled(TextContainer)`
  h2,
  & {
    text-align: left;
  }
`;

const BulletList = styled.ul`
  padding: 0;
  margin: 1.25rem 0 2.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

const bulletPoints = [
  "Unify your security hardware with one intuitive platform",
  "Efficiently scale as your organization grows",
  "Deploy quickly with nationwide installers and US-based support",
];

const CTASection1 = ({ ctaButtonCopy = CTA_BUTTON_COPY_3 }) => {
  return (
    <SectionContainer>
      <SectionInner>
        <FlexCenter>
          <StaticImage
            src="./assets/schools.png"
            alt="Rhombus Access Control for Schools"
            quality={100}
            loading="lazy"
            placeholder="blurred"
            style={{ maxWidth: "560px" }}
          />
        </FlexCenter>
        <FlexCenter>
          <Text width="560px">
            <TitleMed>Streamline your physical security the right way</TitleMed>
            <Body>
              Don&apos;t settle for outdated and capital-intensive legacy
              systems. See how Rhombus can modernize your security with a
              flexible, cloud-centric platform that simplifies your security
              operations across all locations.
            </Body>
            <BulletList>
              {bulletPoints.map((item) => (
                <ListItem>
                  <IconCheckMark color="var(--teal-500)" />
                  {item}
                </ListItem>
              ))}
            </BulletList>
            <RhombusButton
              style={{ padding: "10px" }}
              title={ctaButtonCopy}
              path={CTA_LINK_4}
              useTitleCase={false}
              type="primary-dark"
            />
          </Text>
        </FlexCenter>
      </SectionInner>
    </SectionContainer>
  );
};

export default CTASection1;
